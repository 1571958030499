#admin-products-page {
    & > :first-child {
        font-size: 1.75rem;
        font-weight: 500;
        margin: auto;
        margin-top: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 78vw;

        h3 {
            margin: 1rem;
        }

        button {
            left: 11%;
        }
    }
    .products-container {
        display: flex;
        flex-wrap: wrap;
        gap: 2vw;
        width: 78vw;
        margin: auto;
        margin-top: 4vh;
        box-sizing: border-box;

        a {
            text-decoration: none;
            color: black;
        }

        .product {
            width: 18vw;
            height: 15vw;
            margin-bottom: 2%;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: transform 0.15s ease-in;
            justify-content: space-between;
            cursor: pointer;
            font-weight: 400;
            font-size: 1.35rem;
            box-shadow: -2px 2px 7px rgba(0, 0, 0, 0.349);
            position: relative;
            overflow: hidden;

            .delete {
                position: absolute;
                top: 10px;
                left: 10px;
            }

            &:hover {
                transform: scale(1.06);
            }

            .img-container {
                height: 60%;
                width: 100%;
                overflow: hidden;
                background-color: rgb(226, 223, 223);
                display: flex;

                img {
                    min-width: 100%;
                    max-width: 100%;
                    align-self: flex-start;
                    justify-self: center;
                }
                
                .image {
                    margin: auto;
                    
                }
            }

            h4 {
                margin: auto;
                height: fit-content;
            }
        }
    }
}

.admin-collaborators-page {
    & > :first-child {
        font-size: 1.75rem;
        font-weight: 500;
        margin-top: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            margin: 1rem;
        }

        button {
            left: 11%;
        }
    }
    .loading {
        margin-right: auto;
    }
    .collaborators-container {
        
        display: flex;
        flex-wrap: wrap;
        gap: 2vw;
        width: fit-content;
        max-width: 78vw;
        margin: auto;
        margin-top: 4vh;
        box-sizing: border-box;
    }
    .collaborate {
        width: 18vw;
        height: 15vw;
        margin-bottom: 2%;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: transform 0.15s ease-in;
        justify-content: space-between;
        cursor: default;
        font-weight: 400;
        font-size: 1.35rem;
        box-shadow: -2px 2px 7px rgba(0, 0, 0, 0.349);
        position: relative;
        overflow: hidden;

        .delete,
        .edit {
            position: absolute;
            top: 0.2rem;
            color: #1a3854;
            background-color: white;
            border-radius: 50%;
            padding: 0.2rem;
            width: 1.5rem;
            transition: 0.5s;
            cursor: pointer;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.199);

            &:hover {
                transform: scale(1.1);
            }
        }

        .delete {
            left: 0.5rem;
        }

        .edit {
            left: 2.8rem;
        }

        .img-container {
            height: 60%;
            width: 100%;
            overflow: hidden;
            background-color: rgb(226, 223, 223);

            img {
                // min-height: 100%;
                min-width: 100%;
                max-width: 100%;
            }
        }

        h4 {
            margin: auto;
            height: fit-content;
        }
    }
}

.preview {
    margin: 0 0 0 .3rem;
}

.thumbnail-container {
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    .product-img {
        height: 20vh;
        overflow: hidden;
        position: relative;
        background-color: rgba(255, 255, 255, 0.79);
        .img {
            height: 24rem;
            width: 15rem;
            background-color: rgb(226, 223, 223);
        }
        .close {
            cursor: pointer;
            position: absolute;
            top: 0.2rem;
            right: 0.2rem;
            border: 1px solid #35788c;
            color: #35788c;
            border-radius: 50%;
            background-color: white;
            transition: 0.5s;
            &:hover {
                color: white;
                background-color: #35788c;
            }
        }
        .hilma-tag {
            box-shadow: 1px 2px 8px #00000029;
            background-color: rgba(255, 255, 255, 0.909);
            width: fit-content;
            position: absolute;
            left: 0;
            right: initial;
            border-radius: 0 30px 30px 0;
            top: 4vh;
            padding: 8px 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            height: 1.5rem;
            width: 4rem;
            overflow: hidden;
            .hilma-logo {
                width: 7vw;
                height: auto;
            }
            transition: 0.5s;
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .product-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem;
        &,
        * {
            color: #1a3650;
        }
        .name {
            font-size: 1.8rem;
            font-weight: 700;
        }
        .description {
            font-size: 1.125rem;
        }
    }
}

.top-container {
    position: relative;
}
