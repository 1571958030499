.not-found {
    width: 100vw;
    margin: auto;
    color: #1a3650;
    text-align: center;
    height: 86vh;
    padding-top: 13vh;
    overflow: hidden;
    box-sizing: border-box;

    img {
        height: 15rem;
    }

    p {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .link {
        color: #323b74;
        cursor: pointer;
        text-decoration: underline;
    }
}