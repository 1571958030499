.nav-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    .nav {
        background-color: #f4f4f4;
        height: 10vh;
        width: 100%;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.199);
        display: flex;
        align-items: center;
        padding: 0 1vw;
        box-sizing: border-box;
        position: relative;

        * {
            color: #1a3650;
            font-size: 1.2rem;
        }

        .profile-icon-container {
            width: fit-content;
            height: fit-content;
            border: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            position: absolute;
            left: 3%;
            cursor: pointer;
            * {
                width: 35px;
                height: 35px;
            }
        }

        .links {
            flex: 5;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            & > * {
                text-decoration: none;
                color: #1a3650;
                // font-family: "Assistant";
                font-size: 1.2rem;
                font-weight: 600;
                flex: 1;
                text-align: center;
                transition: font-weight 0.25s ease-in-out;
            }
            .nav-option.true {
                transition: font-weight 0.25s ease-in-out;
                font-weight: 800;
            }
            .link-break {
                margin: 0;
                opacity: 0.6;
            }
        }
        img {
            height: 6vh;
            align-self: flex-start;
            justify-self: center;
            margin: 0 1rem;
        }
        &.rtl {
            .links {
                flex-direction: row-reverse;
            }
        }

        .nav-options {
            padding-right: 2vw;
            height: 100%;
            display: flex;
            align-self: flex-start;
            align-items: center;
            gap: 3vw;

            a {
                text-decoration: none;
            }

            .option {
                font-size: 1.2rem;
            }

            .true {
                font-weight: bold;
            }
        }
    }
}

@media screen and (orientation: portrait) {
    .nav {
        height: 10vh;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.256);
        .profile-lang-container {
            display: none;
        }
        & > img {
            height: 30px;
            width: auto;
        }
        .hamburger {
            cursor: pointer;

            .line {
                width: 28px;
                height: 3px;
                margin: 5px 0;
                background-color: #1a3854;
            }
        }
        .links {
            display: none;
        }
        &.true {
            //portrait navbar design
            background-color: #1a3650;
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 100;
            flex-direction: column;

            * {
                color: white;
                direction: ltr;
                text-align: left;
            }
            & > img {
                display: none;
            }
            .profile-lang-container {
                display: flex;
                width: 100%;
                flex-direction: row-reverse;
                justify-content: space-between;
                flex: initial;
                padding: 0vh 0vw;
                box-sizing: border-box;
                height: 10vh;
                .profile-icon-container {
                    padding: 0;
                    * {
                        height: 35px;
                        width: 35px;
                    }
                }
                .lang {
                    position: absolute;
                    bottom: 10vh;
                    left: 0;
                    margin: 0 10vw;
                    width: 50vw;
                    .css-1s2u09g-control {
                        border-radius: 30px;
                        border: 2px solid white;
                        background-color: #1a3650;
                        color: white !important;
                    }
                }
            }
            .hamburger {
                display: none;
            }
            .links {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex: 10;
                & > * {
                    width: 80vw;
                    margin: 4vh auto;
                    flex: initial;
                    &:first-child {
                        margin-top: 8vh;
                    }
                }
            }
            &.rtl {
                * {
                    direction: rtl;
                    text-align: right;
                }
                .profile-lang-container {
                    .lang {
                        right: 0;
                    }
                }
            }
        }
    }
}
@media screen and (orientation: landscape) and (max-height: 500px) {
    .nav {
        * {
            font-size: 1rem;
        }
    }
}
