#create-tutorials {
    & > :first-child {
        font-size: 1.75rem;
        font-weight: 500;
        margin-top: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            margin: 1rem;
        }
    }

    .tutorials-container {
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.top-container {
    position: relative;
}
