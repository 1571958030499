.singleRow {
    border: 1px solid lightblue;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
}
.cursor {
    cursor: pointer;
}

.deleteItem {
    margin: auto 0;
    cursor: pointer;
}
.addBtn {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}
