.home {
    background-color: #89c5cc;
    height: 90vh;
    box-sizing: border-box;
    display: flex;
    * {
        box-sizing: border-box;
    }

    h1 {
        color: white;
        font-size: 15vh;
        width: fit-content;
        margin-right: 5vw;
        margin-top: 2vh;
        text-shadow: 2px 2px 4px #2d898b70;
        height: fit-content;
    }

    .login {
        color: white;
        font-size: 1.3rem;
        text-align: center;
    }

    .background-img {
        position: absolute;
    }

    .home-ppl {
        height: 60vh;
        left: 1rem;
        bottom: 1rem;
    }
}
