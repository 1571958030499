$lightgray: #f5f5f5;
.editorContainer {
    background-color: $lightgray;
    min-height: 20vh;
    border-radius: 5px;
}

.buttonsContainer {
    width: 100%;
    background: $lightgray;
    text-align: right;
    border-bottom: 0.5px solid lightgray;
    margin-bottom: 3%;
    border-radius: 5px;
}
.button {
    background: none;
    border: none;
    padding: 1%;
    margin: 0 1%;
    border-radius: 5px;
    border: 1px solid transparent;
}
.pressed {
    border: 1px solid lightgray;
}
.label {
    margin-bottom: 1%;
}
.error {
    border: 1px solid #ff2a2a;
}
