.edit_feature_container {
    border: 0.5px solid lightblue;
    margin-bottom: 2%;
}
.selected {
    border: 3px solid lightblue;
}

.icon_option {
    padding: 1%;
}
.iconsContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
.removeRow {
    position: absolute;
    left: 15px;
    cursor: pointer;
}
