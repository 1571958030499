.box {
    // border: 2px solid lightblue;
    box-shadow: 1px 1px 6px #00000030;
    border-radius: 5px;
    padding: 5%;
    margin-top: 4%;
    & > li {
        h3 {
            text-align: right;
            font-weight: 700;
        }
    }
    .cats-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 5%;
        .cats {
            display: flex;
            flex-wrap: wrap;
            .cat {
                margin: 1vh 1vw;
                margin-left: 0;
                margin-bottom: 1vh;
                padding: 3px 5px;
                height: fit-content;
                width: fit-content;
                &:first-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.save-top-btn {
    float: left !important;
}

.delete {
    float: left;
    height: 2rem !important;
    margin-left: 1rem;
    cursor: pointer;
    fill: #35788C;
}

.purchase-options {
    width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 650px;
    padding: 1vh calc(5vh + 24px);
}
